import React, { useRef, useState, useEffect } from 'react';
import ErrorBoundary from '../ErrorBoundary';
import moment from 'moment';    
import { store } from '../utils/httpUtil';
import { logError, checkError } from '../utils/helpers';
import GuestDetailsForm from './GuestDetailsForm';
import {
    Form,
    FormGroup,
    Row,
    Col,
    Label,
    Input,
    Button,
    Alert
} from "reactstrap";

import Header from "./Header";
import { useNavigate } from "react-router-dom";

import {reservationTypes} from '../constants/roomAttributes';  

import "../css/form.css";

function Walkins({ onClose }) {
    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState();

    const navigate = useNavigate(); 

    const [guestDetails, setGuestDetails] = useState({
        guestId: null,  
        referenceId: null,
        idNumber: null,
        firstName: null,
        lastName: null,
        emailId: null,
        phoneNo: null,
        address: null,
        city: null,
        zipCode: null,
        state: null,
        countryId: null,
        ecId: null,
        ecFirstName: null,
        ecLastName: null,
        ecPhoneNo: null,
        ecRelationship: null,
        sendEmail: false    
    });

    const [reservationDetails, setReservationDetails] = useState({
        arrivalDate: moment().format('YYYY-MM-DD'),
        departureDate: new Date(),
        cntPeople: null,
        reservationType: null,   
        rTypeId : []  
    }); 

    function handleReservationTypeChange(e){     
        setReservationDetails({ ...reservationDetails, reservationType: e.target.value }); 
    }

    function populateReservationTypes(){    
        let items = [];
        items.push(<option key={0} value={0}>Select</option>); 
        for (let i = 1; i <= 7; i++) {             
            items.push(<option key={i} value={i}>{reservationTypes[i]}</option>);   
        }    
        return items;
    } 

    const onSubmit = (e) => {
        e.preventDefault(); 

        if (guestDetails.countryId === 0 || guestDetails.countryId === null){
            setAlertMsg("Please select a country!");    
            setAlertColor("danger");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            return;
        }

        if (reservationDetails.reservationType === 0 || reservationDetails.reservationType === null){ 
            setAlertMsg("Please select a reservation type!");
            setAlertColor("danger");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            return;
        }

        let walkInDetails = {
            guest_id: guestDetails.guestId, 
            reference_id: guestDetails.referenceId,
            id_number: guestDetails.idNumber,       
            first_name: guestDetails.firstName,
            last_name: guestDetails.lastName,
            guest_name: guestDetails.firstName + " " + guestDetails.lastName,   
            phone_no: guestDetails.phoneNo,
            email_id: guestDetails.emailId, 
            address: guestDetails.address,
            city: guestDetails.city,
            zip_code: guestDetails.zipCode,
            state: guestDetails.state,
            country_id: guestDetails.countryId,
            guest_emergency_contact_id: guestDetails.ecId,   
            e_first_name: guestDetails.ecFirstName,
            e_last_name: guestDetails.ecLastName,
            e_phone_no: guestDetails.ecPhoneNo,
            e_relationship: guestDetails.ecRelationship,
            arrival_date: reservationDetails.arrivalDate,
            departure_date: reservationDetails.departureDate,
            no_of_people: reservationDetails.cntPeople,
            reservation_type_id: reservationDetails.reservationType,
            send_email: guestDetails.sendEmail, 
            user_id: sessionStorage.getItem("userId")
        };

        console.log(walkInDetails); 

        store("apiReservations", walkInDetails)   
        .then((response) => {
            return checkError(response);
        })
        .then((result) => {   

            setAlertMsg("New reservation saved successfully!"); 
            setAlertColor("success");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                const reservationItem = {
                    rTypeId: reservationDetails.reservationType, 
                    rStatusId: 2,   //Confirmed 
                    rId: result[0].reservation_id, 
                    gId: result[0].guest_id, 
                    guestName: guestDetails.firstName + " " + guestDetails.lastName 
                }    
        
                navigate("/reservationContainer", { state: { reservationItem } });
            }, 2000);

        })
        .catch((error) => {
            setAlertMsg("Oops! Something went wrong! Please try again!")    
            setAlertColor("danger");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            logError(constructor.name + " " + error);
        });
    };

    const checkIfEmailToBeSent = (e) => {
        setGuestDetails({ ...guestDetails, sendEmail: e.target.checked });      
      };

    return (

        <>
        <Header selected="Reservations"/>

        <div className="divError">    
        <ErrorBoundary>
            <div className="divPageContainer">
                <h3>Quick Check In</h3>   
                <hr/>
                    <div className="divFormLayout divBackground">
                            <Alert color={alertColor} isOpen={showAlert}>
                                {alertMsg}
                            </Alert>
                            
                            <GuestDetailsForm
                                guestDetails={guestDetails}
                                setGuestDetails={setGuestDetails}
                                onSubmit={onSubmit}
                            />
                            <Form onSubmit={onSubmit} style={{padding:'0 1em 0 1em'}}>
                            <h6 style={{margin: '2em 0 2em 2em'}}>Reservation Details</h6>
                            <Row style={{marginTop:'-1em'}}>
                                <Col md={4}>
                                    <FormGroup> 
                                        <Label className="form-label">
                                            Reservation Type:   
                                        </Label>

                                        <Input type="select" required className="form-control" value={reservationDetails.reservationType} onChange={(e) => setReservationDetails({ ...reservationDetails, reservationType: e.target.value })}>
                                            {populateReservationTypes()}
                                        </Input>

                                    </FormGroup>
                                </Col>
                            </Row>                              
                            <Row style={{marginTop:'-1em'}}>
                                <Col md={4}>
                                        <FormGroup>
                                            <Label className="form-label">
                                                Arrival Date:
                                            </Label>
                                            <Input type="date" required className="form-control" value = {reservationDetails.arrivalDate}   
                                                onChange={(e) => setReservationDetails({ ...reservationDetails, arrivalDate: e.target.value })}>                                             
                                            </Input>
                                        </FormGroup>
                                </Col>
                                <Col md={4}>    
                                        <FormGroup> 
                                            <Label className="form-label">
                                                Departure Date:
                                            </Label>
                                            <Input type="date" required  className="form-control" value = {reservationDetails.departureDate} min={reservationDetails.arrivalDate}
                                                onChange={(e) => setReservationDetails({ ...reservationDetails, departureDate: e.target.value })}>                                                    
                                            </Input>
                                        </FormGroup>
                                </Col>
                                <Col md={4}>    
                                    <FormGroup> 
                                        <Label className="form-label">
                                            Guests #:    
                                        </Label>
                                        <Input type="text" required  className="form-control" value={reservationDetails.cntPeople} 
                                            onChange={(e) => setReservationDetails({ ...reservationDetails, cntPeople: e.target.value })}>                                                    
                                        </Input>    
                                    </FormGroup>
                                </Col>
                            </Row>                
                            <Row style={{marginTop:'-1em'}}>
                                <Col md={6}>
                                    <div className="send-email">
                                        <input type="checkbox" onChange={checkIfEmailToBeSent} style={{marginRight:'0.5em'}}/>
                                            Send email to guest 
                                    </div>
                                </Col>   
                                <Col md={6}>   
                                    <div className="save-button-container">
                                        <button className="btnBig" type="submit">Allocate Room(s)</button>      
                                    </div>
                                </Col>
                            </Row>

                            </Form>
                    </div>
            </div>
        </ErrorBoundary>
        </div>
        </>

    );
}

export default Walkins;
